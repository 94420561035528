@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");

@layer base {
  body {
    @apply font-[Raleway];
  }

  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.contentDiv {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.contentDiv:hover {
  background-image: linear-gradient(
    to right,
    rgba(112, 157, 255, 0.8),
    hsla(242, 74%, 61%, 0.8)
  ) !important;
}

/* Regular styles for light mode */
.bg-custom {
  background-color: #ffffff; /* Light background */
  color: #000000; /* Dark text */
}

/* Dark mode styles */
.dark .bg-custom {
  background-color: #000000; /* Dark background */
  color: #ffffff; /* Light text */
}
.hide-skills {
  display: none;
}
